<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";

/**
 * Starter page component
 */
export default {
  page() {
    return {
      title: this.title,
      meta: [{ name: "description" }],
    };
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Starter page",
      items: [
        {
          text: "Utility",
          href: "/",
        },
        {
          text: "Starter page",
          active: true,
        },
      ],
      userdetail: [],
      ismobile: false,
      activeNames: [],
      activeapp: [],
      showmodel: "1",
      userinfo: [],
      safeinfo: [],
      loading: true,
      adminid: 0,
      chargetype: false,
      showamountchange: false,
      showpointschange: false,
      showdiscountchange: false,
      amountchangedata: {
        name: "",
        label: "+",
        value: 0,
      },
      discountchangedata: {
        name: "",
        value: 100,
      },
    };
  },
  mounted() {
    var that = this;
    that.title = that.$t("menuitems.user.userdetail");
    that.items = [
      {
        text: that.$t("menuitems.user.list"),
        href: "/user/list",
      },
      {
        text: that.$t("menuitems.user.userdetail"),
        active: true,
      },
    ];

    that.userinfo = JSON.parse(localStorage.getItem("user"));
    let flag = navigator.userAgent.match(
      /(phone|pod|iPhone|iPod|ios|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    );
    if (flag) {
      // that.ismobile = true;
    }
    that.adminid = that.$route.query.id;
    that.getuserdetail();
    that.getchargetype();
  },
  methods: {
    getuserdetail() {
      var that = this;
      that.$axios
        .post(
          that.apiuri,
          {
            action: "getuserinfo",
            id: that.adminid,
          },
          {
            headers: { Openid: that.userinfo.openid },
          }
        )
        .then(function (response) {
          that.loading = false;
          that.userdetail = response.data.data;
          that.bindcarduid = that.userdetail.id;
          that.bindcardhex = that.userdetail.hex;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    unbindcard() {
      var that = this;
      that
        .$confirm(
          that.$t("global.delete.deletecardtip"),
          that.$t("global.delete.sureyouaction"),
          {
            confirmButtonText: that.$t("global.button.ok"),
            cancelButtonText: that.$t("global.button.cancel"),
            type: "warning",
          }
        )
        .then(() => {
          that.loading = true;
          that.$axios
            .post(
              that.apiuri,
              {
                action: "lostcard",
                uid: that.userdetail.id,
              },
              {
                headers: { Openid: that.userinfo.openid },
              }
            )
            .then(function (response) {
              that.loading = false;
              if (response.data.status == 200) {
                that.$message({
                  message: that.$t("global.delete.success"),
                  type: "success",
                });
              } else if (response.data.status == 403) {
                that.$message({
                  message: that.$t("global.delete.auth"),
                  type: "warning",
                });
              } else {
                that.$message.error(that.$t("global.delete.500"));
              }
              that.getuserdetail();
            })
            .catch(function (error) {
              console.log(error);
            });
        })
        .catch(() => { });
    },
    getchargetype(){
      var that = this;
      that.$axios
        .post(
          that.apiuri,
          {
            action: "chargetype",
          },
          {
            headers: { Openid: that.userinfo.openid },
          }
        )
        .then(function (response) {
          if(response.data.status==200) that.chargetype = 1;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    amountchange() {
      var that = this;
      that.showamountchange = true;
    },
    pointchange() {
      var that = this;
      that.showpointschange = true;
    },
    discountchange() {
      var that = this;
      that.showdiscountchange = true;
    },
    chargeamount() {
      var that = this;
      that.showamountchange = false;
      that.$axios
        .post(
          that.apiuri,
          {
            action: "changecard",
            uid: that.adminid,
            amount: that.amountchangedata.label + that.amountchangedata.value,
            note: that.amountchangedata.name,
          },
          {
            headers: { Openid: that.userinfo.openid },
          }
        )
        .then(function (response) {
          that.getuserdetail();
          if (response.data.status == 200) {
            that.$message({
              message: that.$t("global.success"),
              type: "success",
            });
          } else {
            that.$message.error(that.$t("global.delete.500"));
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    changediscount() {
      var that = this;
      that.showdiscountchange = false;
      that.$axios
        .post(
          that.apiuri,
          {
            action: "changediscount",
            uid: that.adminid,
            discount: that.discountchangedata.value,
            note: that.discountchangedata.name,
          },
          {
            headers: { Openid: that.userinfo.openid },
          }
        )
        .then(function (response) {
          that.getuserdetail();
          if (response.data.status == 200) {
            that.$message({
              message: that.$t("global.success"),
              type: "success",
            });
          } else {
            that.$message.error(that.$t("global.delete.500"));
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    amountlogs() {
      this.$router.push({ name: "UserAmountLog", query: { id: this.$route.query.id } });
    },
    orderlogs() {
      this.$router.push({ name: "UserOrderLog", query: { id: this.$route.query.id } });
    },
  },
  destroyed() {
    this.bindcarduid = "";
    this.bindcardhex = "";
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body" v-loading="loading">
            <table class="table">
              <tbody>
                <tr>
                  <td>{{ $t("admin.lists.username") }}</td>
                  <td colspan="2">{{ userdetail.username }}</td>
                </tr>
                <tr>
                  <td>{{ $t("admin.lists.phone") }}</td>
                  <td colspan="2">
                    +{{ userdetail.country }}{{ userdetail.phone }}
                  </td>
                </tr>
                <tr>
                  <td>{{ $t("admin.detail.currency") }}</td>
                  <td colspan="2">
                    {{ $t("login.currency." + userdetail.currey) }}({{
                      userdetail.currey
                    }})
                  </td>
                </tr>
                <tr>
                  <td>{{ $t("user.list.membercard") }}</td>
                  <td colspan="2">{{ userdetail.cardno }}</td>
                </tr>
                <tr>
                  <td>{{ $t("user.detail.amount") }}</td>
                  <td>{{ userdetail.amount }}</td>
                  <td>
                    <a href="javascript:;" class="badge badge-info" @click="amountchange" v-if="chargetype==0">{{
                      $t("user.detail.changeamount") }}</a>
                  </td>
                </tr>
                <tr>
                  <td>{{ $t("user.detail.discount") }}</td>
                  <td>{{ userdetail.discount * 100 }} %</td>
                  <td>
                    <a href="javascript:;" class="badge badge-info" @click="discountchange">{{
                      $t("user.detail.changediscount") }}</a>
                  </td>
                </tr>
                <tr>
                  <td>{{ $t("user.detail.point") }}</td>
                  <td>{{ userdetail.point }}</td>
                  <td>
                    <a href="javascript:;" class="badge badge-info" @click="pointchange">{{ $t("user.detail.pointchange")
                    }}</a>
                  </td>
                </tr>
                <tr>
                  <td>{{ $t("user.detail.card") }}</td>
                  <td>
                    <a href="javascript:;" class="badge badge-success" v-if="userdetail.hex" @click="unbindcard">{{
                      $t("user.detail.cardbind") }}</a>
                    <a href="javascript:;" class="badge badge-info" v-else>{{ $t("user.detail.cardnobind") }}</a>
                  </td>
                  <td></td>
                </tr>
                <tr>
                  <td colspan="3">
                    <el-button type="primary" @click="amountlogs">{{
                      $t("user.detail.amountlogs")
                    }}</el-button>
                    <el-button type="primary" @click="orderlogs">{{
                      $t("user.detail.orderlogs")
                    }}</el-button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- Dialog -->
          <el-dialog :title="$t('user.detail.changeamount')" :visible.sync="showamountchange"
            :close-on-click-modal="false" :close-on-press-escape="false">
            <el-form :model="amountchangedata">
              <el-form-item :label="$t('user.detail.changename')">
                <el-input v-model="amountchangedata.name" autocomplete="off"></el-input>
              </el-form-item>
              <el-form-item :label="$t('user.detail.costlabel')">
                <el-select v-model="amountchangedata.label" :placeholder="$t('user.detail.costlabel')">
                  <el-option label="+" value="+"></el-option>
                  <el-option label="-" value="-"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item :label="$t('user.detail.price')">
                <el-input v-model="amountchangedata.value" autocomplete="off"></el-input>
              </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
              <el-button @click="showamountchange = false">{{
                $t("global.button.cancel")
              }}</el-button>
              <el-button type="primary" @click="chargeamount()">{{
                $t("global.button.ok")
              }}</el-button>
            </div>
          </el-dialog>

          <el-dialog :title="$t('user.detail.changediscount')" :visible.sync="showdiscountchange"
            :close-on-click-modal="false" :close-on-press-escape="false">
            <el-form :model="discountchangedata">
              <!-- <el-form-item :label="$t('user.detail.changename')">
                <el-input v-model="discountchangedata.name" autocomplete="off"></el-input>
              </el-form-item> -->
              <el-form-item :label="$t('user.detail.discount')">
                <el-input placeholder="请输入内容" type="number" v-model="discountchangedata.value">
                  <template slot="append">%</template>
                </el-input>
              </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
              <el-button @click="showdiscountchange = false">{{
                $t("global.button.cancel")
              }}</el-button>
              <el-button type="primary" @click="changediscount()">{{
                $t("global.button.ok")
              }}</el-button>
            </div>
          </el-dialog>
        </div>
      </div>
    </div>
  </Layout>
</template>